<template>
  <div class="homeContainer" style="width: 100%;">
    <div class="topView" style="width: 100%;">
      				
      <div class="topMainBtn" :style="select_tab=='bulletin' ? 'background-color: #4880F5;' : '' " @click="changeView('bulletin')">
        Bulletin
      </div>
      <div class="topMainBtn" :style="select_tab=='myEvents' ? 'background-color: #4880F5;' : '' " @click="changeView('myEvents')">
        My Events
      </div>
      <div class="topMainBtn" :style="select_tab=='learningHub' ? 'background-color: #4880F5;' : '' " @click="changeView('learningHub')">
        Learning Hub
      </div>
      <!-- <div class="topMainBtn" :style="select_tab=='learningmaterials' ? 'background-color: #4880F5;' : '' " @click="changeView('learningmaterials')">
        Resources
      </div> -->
      <div class="topMainBtn" :style="select_tab=='message' ? 'background-color: #4880F5;' : '' " @click="changeView('message')">
        Messages
      </div>
      <div class="topMainBtn" :style="select_tab=='honorprofile' ? 'background-color: #4880F5;' : '' " @click="changeView('honorprofile')">
        Honor Profile
      </div>
  


      <div class="topLog" :style="select_tab=='accountInfo' ? 'background-color: #4880F5;' : '' "  >
        <div class="studentNameStyle" @click="changeView('accountInfo')">
          {{ student_name }} <br>( {{ account_country }}, {{ account_continent }} )
        </div>
        <div class="logoutStyle" @click="changeView('logout')">
          Log out
        </div>
      </div>

      

    </div>
    <div class="main">

   

      <!-- <div class="mainRight"> -->
        <router-view></router-view>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {
  logout,
  getUserType,
  getUserName,
  getAccountName,
  getAccountCountry,
  getAccountContinent,
  getAvatarUrl,
  getProjectName, getProjectCode
} from "../utils/store";
import {
  getProjectInfo
} from '../api/index'

export default {
  data() {
    return {
      nav: [
        {
          name: "Notices & Information",
          selected: require("../assets/nav/peixun1.png"),
          unselected: require("../assets/nav/peixun.png"),
          router: "/announcements",
        },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/studentlist",
        },
        // {
        //   name: "Rankings",
        //   selected: require("../assets/nav/chengji1.png"),
        //   unselected: require("../assets/nav/chengji.png"),
        //   router: "/score",
        // },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        {
          name: "Contact Us",
          selected: require("../assets/nav/xiaoxi1.png"),
          unselected: require("../assets/nav/xiaoxi.png"),
          router: "/message",
        },
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/teacherlist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      navEmployee: [
        // {
        //   name: "赛前培训",
        //   selected: require("../assets/nav/peixun1.png"),
        //   unselected: require("../assets/nav/peixun.png"),
        //   router: "/peixunadmin",
        // },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/employeelist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {
        //   name: "监考",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/examadminlist",
        // },
        // // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      user_type: getUserType(),
      project_name: getProjectName(),
      currentNav: "/peixun",
      student_name: "",
      student_school: "",
      account_country: "",
      account_continent: "",
      application_project: "",
      avatar_url: "",
      select_tab: localStorage.getItem('selectedTab') || 'bulletin',
    };
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });
    this.student_name = getUserName();
    this.student_school = getAccountName();
    this.account_country = getAccountCountry();
    this.account_continent = getAccountContinent();
    this.avatar_url = getAvatarUrl();
    // this.currentNav = this.$route.path;
    this.requestProjectConfig()
  },
  methods: {

    requestProjectConfig() {
      // getProjectInfo(getProjectCode()).then((res) => {
      //   let project = res.data
      //   if (project.project_exam_system_results_model_show && project.project_exam_system_results_model_show == 'show') {
      //     this.nav.push(
      //         {
      //           name: "Results",
      //           selected: require("../assets/nav/award1.png"),
      //           unselected: require("../assets/nav/award.png"),
      //           router: "/newaward",
      //         },
      //     )
      //   }
      // })
    },

    logout() {
      logout();
      location.reload();
    },
    changeView(item) {

      if (item == 'logout'){
        this.$confirm("Are you sure you want to log out?", "Logout Confirmation.", {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
        return
      }

      console.log(2241412,item)

      this.select_tab = item
      let shouldReturn = false;
      if (item == 'myEvents'){
        this.$router.push('/myevents');
        shouldReturn = true;
      }
      else if (item == 'bulletin'){
        this.$router.push('/bulletin');
        shouldReturn = true;
      }
      else if (item == 'message'){
        this.$router.push('/message');
        shouldReturn = true;
      }
      else if (item == 'learningmaterials'){
        this.$router.push('/learningmaterials');
        shouldReturn = true;
      }
      else if (item == 'learningHub'){
        this.$router.push('/learninghub');
        shouldReturn = true;
      }
      else if (item == 'accountInfo'){
        this.$router.push('/personalinfo');
        shouldReturn = true;
      }
      else if (item == 'honorprofile'){
        // this.$router.push('/personalinfo');
        shouldReturn = true;
      }

      // Save the selected item in cache
      localStorage.setItem('selectedTab', item);
      
      if (shouldReturn) {
        return;
      }

      if (item.router) {
        this.currentNav = item.router;
        this.$router.push(item.router);
      } else {
        this.$confirm("Are you sure you want to exit?", "Tips", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #FFFAF4;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.menuStyle {
  color: #C5C9DE;
  align-content: center;
  text-align: left;
  margin-top: 20px;
  margin-left: 50px;
  font-size: 15px;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .topView {
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    height: 80px;
    background-repeat: no-repeat;
    background-color: #072D76;
    background-size: cover;
    width: 100%;
    cursor: pointer;

    .topLog{
      width: 16%;
      background-color: #072D76;
      position: absolute;
      right: 0;
      top: 0;
    }
    .topMainBtn{
      width: 14%;
      height: 80px;
      color: white;
      line-height: 80px;
      text-align: center;
      background-color: #072D76;
    }

    .logoutStyle {
      margin-right: 20px;
      height: 20px;
      float: right;
      width: 50%;
      margin-top: 0px;
      color: white;
      text-align: right;
      line-height: 20px;
    }

    .studentNameStyle {
      margin-left: 0px;
      margin-right: 20px;
      margin-top: 20px;
      font-size: 13px;
      color: white;
      text-align: right;
    }

    .studentlogo {
      position: absolute;
      margin-top: 20px;
      margin-left: 40px;
      width: 22px;
      height: 20px;
    }

  }

  .main {
    padding: 0;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;   
  }
}
</style>
